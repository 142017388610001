import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet.js'
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet'
import './App.css';
import AimpointStores from "./data/aimpoint-stores.json"

// For external links (new window and no referrer)
function E({href, children}) {
  return (<a href={href} target="_blank" rel="noreferrer">{children}</a>)
}

function PlacePopup({ place }) {
  return (<div>
    <div>{place.name}</div>
    <div>Gatuadress: {place.streetAddress}</div>
    <div>Stad: {place.city}</div>
    <div>Postnummer: {place.postcode}</div>
    <div><a href={`https://duckduckgo.com/?q=${place.lat}, ${place.lng}&iaxm=maps`} target="_blank" rel="noreferrer">Vägbeskrivning</a></div>
    <div>Telefonnummer: <a href={`tel:${place.phone}`}>{place.phone}</a></div>
    <div>Email: <a href={`mailto:${place.email}`}>{place.email}</a></div>
    <div>Hemsida: <E href={place.website}>{place.website}</E></div>
  </div>)
}

function App() {
  const places = AimpointStores.map(store => ({
    ...store,
    streetAddress: store["street address"],
    website: store.website && (store.website.startsWith('http://') || store.website.startsWith('https://')
      ? store.website : `https://${store.website}`)
  }))

  return (
    <div className="App">
      Aimpoint är ett företag som producerar sikten för vapen som säljs till <E href="https://www.etc.se/inrikes/sikten-fraan-svenska-aimpoint-anvaends-av-idf">israelisk militär</E>. Den här kartan visar Aimpoints <E href="https://www.aimpoint.com/se/aaterfoersaeljare/">återförsäljare</E> i Sverige. Sidan uppdateras.
      <MapContainer style={{ height: '90vh' }} center={[55.6088, 12.9997]} zoom={11} maxZoom={19} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {places.map(place => (
          <Marker position={[place.lat, place.lng]} key={`${place.name}${place.lat}${place.lng}`}>
            <Popup>
              <PlacePopup place={place} />
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <a href="mailto:blodspengar@riseup.net">Contact us</a>. <a href="blodspengar.asc">GPG key</a>.
    </div>
  );
}

export default App;
